import React from 'react'

const MyBlogs = () => {
  return (
    <div className='section'>
        <section className='blog-section hidden show-on-scroll' id='Blog'>
            <h1 className='animate-character dark:text-[#FAFDFF] text-darkFont2 font-medium tablet:text-[2.9em] laptop:text-[4em] tablet:text-center'>/ blog-posts</h1>
            <p className='mt-[0.5em] dark:text-lightFont2 text-darkFont2 tablet:text-center laptop:text-[1.3em]'>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Earum asperiores nobis illum placeat temporibus quas natus, at beatae voluptas corporis, minus optio eaque commodi eos. Sit labore impedit dolorum placeat?
            </p>
        </section>
    </div>
  )
}

export default MyBlogs