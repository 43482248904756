import React from 'react'
import { Context, useContext } from '../context';
import footerDark from '../assets/footer-dark.png'
import footerLight from '../assets/footer-light.png'

const FooterDecor = () => {
  const { getters } = useContext(Context);
  const [isDarkMode] = [getters.isDarkMode];
  return (
    <div>
      <img src={isDarkMode ? footerDark : footerLight} alt="" />  
    </div>
  )
}

export default FooterDecor