import React from 'react'
import ProjectCard from './ProjectCard';
import weemi from '../assets/weemi.png'
import travel from '../assets/travel.png'

const MyProjects = ({ projectsScrollRef }) => {
  return (
    <div className='section pt-0 hidden'>
        <div className="landing-layout1">
          <section className='projects-section show-on-scroll' id='Projects'>
              <h1 className='animate-character dark:text-[#FAFDFF] text-darkFont2 font-medium tablet:text-[3em] laptop:text-[4em] tablet:text-center'>/ projects</h1>
              <div className='proj-body'>
                <ProjectCard source={weemi} header='Weemi'/>
                <ProjectCard source={travel} header='TravelConnect'/>
                <ProjectCard/>
                <ProjectCard/>
              </div>
              <p className='mt-[0.5em] dark:text-lightFont2 text-darkFont2 tablet:text-center laptop:text-[1.3em]'>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Earum asperiores nobis illum placeat temporibus quas natus, at beatae voluptas corporis, minus optio eaque commodi eos. Sit labore impedit dolorum placeat?
              </p>
                </section>
        </div>

    </div>
  )
}

export default MyProjects