import { React, useState, useRef } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import { Context, initial } from './context.js';
import Home from './pages/Home';

function App() {
  const [activeTab, setActiveTab] = useState(initial.activeTab);
  const [isDarkMode, setIsDarkMode] = useState(initial.isDarkMode);

  const getters = {
    activeTab,
    isDarkMode,
  }

  const setters = {
    setActiveTab, 
    setIsDarkMode,
  }
  
  return (
   <div className='overflow-hidden'>
    <Context.Provider value={{ getters, setters }}>
      <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />}/>
          </Routes>
        </BrowserRouter>
    </Context.Provider>
   </div>
  );
}

export default App;
