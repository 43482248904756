import React from "react";
import { Context, useContext } from "../context";

const SkillTab = ({ imgSrc, darkImgSrc, tabName }) => {
  const { getters, setters } = useContext(Context);
  const [isDarkMode, setIsDarkMode] = [
    getters.isDarkMode,
    setters.setIsDarkMode,
  ];

  return (
    <div className="skill-tab items-center gap-3 justify-start">
      {/* <div className="h-max p-1.5 bg-[#3F84DA] rounded-full">
        <img
          src={isDarkMode ? darkImgSrc : imgSrc}
          alt="language-icon"
          className="skill-tab-icon"
        />
      </div> */}
      <h3>{tabName}</h3>
    </div>
  );
};

export default SkillTab;
