import React from 'react'
import ContactButtons from './ContactButtons'

const Footer = ({ footerScrollRef }) => {
  return (
    <div 
      className='footer'
      id='Contact'
      ref={footerScrollRef}
    >
        <ContactButtons></ContactButtons>
        <h3 className='footer-subheader'>Made in 2023 &#9829;</h3>
    </div>
  )
}

export default Footer