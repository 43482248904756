import React from 'react'
import { Context, useContext } from '../context';
import headerDark from '../assets/header-dark.png'
import headerLight from '../assets/header-light.png'

const HeaderDecor = () => {
  const { getters } = useContext(Context);
  const [isDarkMode] = [getters.isDarkMode];
  return (
    <div>
        <img src={isDarkMode ? headerDark : headerLight} className='pt-[4.9rem] relative laptop:hidden' alt="" />
    </div>
  )
}

export default HeaderDecor