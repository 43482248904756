import { React, useEffect, useRef } from 'react';
import { Context, useContext } from '../context';
import ScrollToTop from '../components/ScrollToTop'
import Navbar from '../components/Navbar';
import HeaderDecor from '../components/HeaderDecor';
import Hero from '../components/Hero';
import AboutMe from '../components/AboutMe';
import TechStack from '../components/TechStack';
import MyProjects from '../components/MyProjects';
import MyBlogs from '../components/MyBlogs';
import FooterDecor from '../components/FooterDecor';
import Footer from '../components/Footer';
import eye from '../assets/eye.png'

const Home = () => {
  const { getters } = useContext(Context);
  const [isDarkMode] = [getters.isDarkMode];

  // Scroll content into view
  const callback = function (entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("animate-fadeIn");
      } else {
        entry.target.classList.remove("animate-fadeIn");
      }
    });
  };
  const observer = new IntersectionObserver(callback);
  const targets = document.querySelectorAll(".show-on-scroll");
  targets.forEach(function (target) {
    target.classList.add("opacity-0");
    observer.observe(target);
  });

  // Smooth scroll
  const Home = useRef(null);
  const About = useRef(null);
  const Projects = useRef(null);
  // const TechStack = useRef(null);
  const Blogs = useRef(null);
  const footer = useRef(null);
  function scrollDown (sectionName) {
    window.scrollTo({
      top: sectionName.current.offsetTop,
      behavior: 'smooth',
    });
  };

  // Moving eyeballs
  // document.addEventListener('mousemove', (e) => {
  //   // console.log(e);
  //   const mouseX = e.clientX;
  //   const mouseY = e.clientY;
  //   const anchor = document.getElementById('anchor');
  //   const rect = anchor.getBoundingClientRect();
  //   const anchorX = rect.left + rect.width / 2;
  //   const anchorY = rect.top + rect.height / 2;

  //   const angleDeg = angle(mouseX, mouseY, anchorX, anchorY);
  //   const eyes = document.querySelectorAll('.eye')
  //   eyes.forEach(eye => {
  //     eye.style.transform = `rotate(${90 + angleDeg}deg)`;
  //   })
  // })

  // function angle(cx, cy, ex, ey) {
  //   const dy = ey - cy;
  //   const dx = ex - cx;
  //   const rad = Math.atan2(dy, dx);
  //   const deg = rad * 180 / Math.PI;
  //   return deg;
  // }

  // Sliding skills tabs

  // Tech stack
  useEffect(() => {
    var tabs = document.querySelectorAll('.tab');
    var indicator = document.querySelector('.indicator');
    var panels = document.querySelectorAll('.tab-panel');

    indicator.style.height = tabs[0].getBoundingClientRect().height + 'px';
    indicator.style.top = tabs[0].getBoundingClientRect().top - tabs[0].parentElement.getBoundingClientRect().top + 'px'

    tabs.forEach(tab => {
      tab.addEventListener('click', () => {
        var tabTarget = tab.getAttribute('aria-controls')
        indicator.style.height = tab.getBoundingClientRect().height + 'px';
        indicator.style.top = tab.getBoundingClientRect().top - tab.parentElement.getBoundingClientRect().top + 'px'

        panels.forEach(panel => {
          var panelId = panel.getAttribute('id')
          if (tabTarget === panelId) {
            panel.classList.remove('hidden', 'opacity-0')
            panel.classList.add('flex', 'opacity-100')
          } else {
            panel.classList.add('hidden', 'opacity-0')
          }
        })
      })
    })
  });

  useEffect(() => { 
    const tabs = document.querySelectorAll(".tab");
    tabs.forEach((tab) => {
      var tabTarget = tab.getAttribute('aria-selected');
      if (tabTarget === 'true') {
        tab.classList.remove('border-r-slate-300')
        tab.classList.add('border-r-teal-300', 'border-r-6')      } else { 
      }
      console.log(tab);
    })
  });

  return (
    <div>
      <ScrollToTop></ScrollToTop>
      <Navbar scrollDown={scrollDown}/>
      <HeaderDecor/>
      <Hero
          scrollFunction={scrollDown}
          HomeScrollRef={Home}
          footerRef={footer}
          className='show-on-scroll'
      ></Hero>
      <AboutMe aboutScrollRef={About}/>
      <TechStack techStackScrollRef={TechStack}></TechStack>
      <MyProjects projectsScrollRef={Projects}/>
      <MyBlogs/>
      <FooterDecor/>
      <Footer footerScrollRef={footer}/>
    </div>
  )
}

export default Home