import { React } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { Context, useContext } from '../context';

import portrait from '../assets/photo.jpeg'

const AboutMe = ({ aboutScrollRef }) => {

  const { getters } = useContext(Context);

  return (
    <div className='section pt-0'>
        <div className='default-margin-layout'>
          <section
            className='about-section show-on-scroll'
            id='About'
            ref={aboutScrollRef}>
            <section className='about-header-section'>
              <h1 className='about-header'>/ About Me </h1>
              {/* <span className='tablet:hidden section-line'></span> */}
            </section>
            <section className='about-body'>
              <div className='about-content'>
                Hiya I'm Emily - thanks for visiting my page! Here's a bit about me:
                <br /><br />
                <h1>What I'm doing currently...</h1>
                <ul>
                  <li>Casual Academic <a href="https://www.unsw.edu.au/">@UNSW</a></li>
                  <li>Front-end engineer <a href="https://mammoth.tech/">@Woolly Mammoth</a></li>
                </ul>
                <br />
                <h1>What I've done previously...</h1>
                <ul>
                  <li>IT Director <a href="https://www.unswdigitalsociety.org/">@UNSW Digital Society</a></li>
                  <li>CSE Projects member <a href="https://www.csesoc.unsw.edu.au/">@UNSW Comp. Sci/ Engineering Society</a></li>
                  <li>Data Solutions Engineer Intern <a href="https://www.telstra.com.au/aboutus/our-company">@Telstra</a></li>
                </ul>
                <p>
                  <br />
                  When I'm not finishing off my degree at UNSW I'm playing badminton with friends, finding nice ramen places in Sydney or tinkering on side projects!
                </p>
                <br />
              </div>
              <img className='portrait' src={portrait} alt="" />
            </section>
          </section>
          <section className='fun-stuff-section hidden'>
          <p>In my spare time, you can catch me rewatching <a href="" className='link-decor dark:text-[#E0E0E0] text-darkFont2'>AOT</a> for the 120398th time, finding nice ramen places in Sydney or revamping my <a className='link-decor dark:text-[#E0E0E0] text-darkFont2' href="https://open.spotify.com/user/emilyyy496?si=dcf0986a334f4ee">Spotify</a> playlists!</p>
          </section>

        </div>
      </div>
  )
}

export default AboutMe