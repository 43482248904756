import React from 'react'

const SkillTag = ({ skillName, logo }) => {
  return (
    <div className='skill-tag'>
      <img className='skill-tag-icon' src={logo} alt="" />
      <h3 className='skill-tag-name'>{skillName}</h3>
    </div>
  )
}

export default SkillTag