import React from 'react'
import { Context, useContext } from '../context';

import lightBlobs from '../assets/blobs-1.png'
import darkBlobs from '../assets/blobs-2.png'
import lightBlobChild from '../assets/blobs-1-contracted.png'
import darkBlobChild from '../assets/blobs-2-contracted.png'
import lightBlobSmall from '../assets/blobs-1-vcontracted.png'
import darkBlobsSmall from '../assets/blobs-2-vcontracted.png'
import resume from '../assets/resume-2024.pdf'

const Hero = ({ scrollFunction, landingScrollRef, footerRef }) => {
  const { getters } = useContext(Context);
  const [isDarkMode] = [getters.isDarkMode];

  return (
    <div className='section laptop:pt-[11rem]'>
      <div className='default-margin-layout flex flex-row'>
            <div className='landing-section'>
              <section
                className='scroll-m-[5rem] show-on-scroll'
                ref={landingScrollRef}
                id='Home'
              >
                <h1 className='landing-header'> Hi there, I'm</h1>
                <div
                  className="typing-text-container show-on-scroll"
                >
                  <div className="flex tablet:justify-center">
                    <h1 className="typing-text">Emily!</h1>
                  </div>
                </div>
                <div className='landing-subHeader-container show-on-scroll'>
                    <h3
                    className='landing-subHeader animate-slideInBottom'
                    >Frontend Engineer</h3>
                    <h3
                    className='landing-subHeader animate-slideInBottom'>Design Enthusiast</h3>
                    <h3
                    className='landing-subHeader animate-slideInBottom'>Student @ UNSW</h3>
                </div>
                <h1 className='landing-body show-on-scroll animate-slideInBottom'>Welcome to my page! I'm a final year student studying B.Computer Science/B.Commerce (Information Systems) student at <a className='link-decor dark:text-[#E0E0E0] text-darkFont2' href="https://www.linkedin.com/school/unsw/?originalSubdomain=au"
                target="_blank">UNSW</a>. I'm passionate about <a className='link-decor dark:text-[#E0E0E0] text-darkFont2 whitespace-nowrap'
                href="#">front-end engineering</a>, <a className='link-decor dark:text-[#E0E0E0] text-darkFont2 whitespace-nowrap' href="#">UI/UX design</a> and anything tech-related.
                </h1>
                <div className='flex flex-row gap-4 flex-wrap tablet:justify-center laptop:justify-start
                mt-[1.5em]
                show-on-scroll animate-slideInBottom'>
                  <button className='contact-buttons'><a href={resume} target='_blank'>View Resume</a></button>
                  <button
                  className='contact-buttons'
                  onClick={() => {scrollFunction(footerRef)}}
                  >Get In Touch!</button>
                </div>
              </section>
            </div>
            <picture>
              <source
              media='(min-width: 1000px) and (max-width: 1240px)'
              srcSet={isDarkMode ? darkBlobChild : lightBlobChild}
              id='anchor'/>
              <source
              media='(min-width: 850px) and (max-width: 1000px)'
              srcSet={isDarkMode ? darkBlobsSmall : lightBlobSmall}
              id='anchor'/>
              <img
              className='blob-monster '
              src={isDarkMode ? darkBlobs : lightBlobs} alt=""
              id='anchor'/>
            </picture>
          </div>
    </div>
  )
}

export default Hero