import { React, useEffect } from 'react'
import titleIcon from '../assets/title-icon.png'
import profileBlobLight from '../assets/profile-blob1.png'
import profileBlobDark from '../assets/profile-blob2.png'
import { useNavigate } from 'react-router'
import { Context, useContext } from '../context';
import { MdDarkMode } from 'react-icons/md';
import { CiLight } from 'react-icons/ci';
import { changeDisplay, initialiseDarkMode } from '../helpers';

const Navbar = ({scrollDown}) => {
  // const navigate = useNavigate();
  const { getters, setters } = useContext(Context);
  const [activeTab, setActiveTab] = [getters.activeTab, setters.setActiveTab];
  const [isDarkMode, setIsDarkMode] = [getters.isDarkMode, setters.setIsDarkMode];

  const tabs = [{ tabName: 'About', id: 'About' }, { tabName: 'Tech Stack', id: 'TechStack' }, { tabName: 'Contact', id: 'Contact'}];

  const displayIconStyling = { 
    color: `${isDarkMode ? '#d69c29' : '#5487DE'}`,
  }

  useEffect(() => { 
      initialiseDarkMode(setIsDarkMode);
  }, []);

  return (
    <div className='navbar-desktop animate-slideInTop'>
        <div>
          <a href="#Home">
            <img
            src={isDarkMode ? profileBlobDark : profileBlobLight}
            className='return-to-home-icon'
            alt="title-icon"
            />
          </a>
        </div>
        
        <div className='flex flex-row justify-center mr-3 items-center'>
          {tabs.map((tab, idx) => { 
            return (
              <a 
              key={idx}
              className='tablet:hidden nav-tabs'
              // className={`tablet:hidden nav-tabs ${idx === activeTab ? 'bg-teal-400 bg-opacity-20 rounded-lg' : 'bg-inherit'}`}
              onClick={() => {
                setActiveTab(idx)
                // {scrollDown(tabs.label)}
              }}
              href={`#${tab.id}`}
              >
              {/* <h3 className={`${idx === activeTab ? 'text-teal-500' : ''}`}>{tab.label}</h3> */}
              <h3>{tab.tabName}</h3>

              </a>
            )
          })}

          <div className='mode-hover'
          onClick={() => 
            changeDisplay(isDarkMode, setIsDarkMode)
          }
          >
            {isDarkMode ? (
            <CiLight 
            style={displayIconStyling}
            className='mode-icon'
            ></CiLight>
          ) : (<MdDarkMode
            style={displayIconStyling}
            className='mode-icon'
            ></MdDarkMode>)}
          </div>
        </div>
        
    </div>
  )
}

export default Navbar