import { React, useEffect } from 'react';
import html from '../assets/html.png'
import css from '../assets/css-3.png'
import python from '../assets/python.png'
import javascript from '../assets/js.png'
import postgresql from '../assets/database.png'
import react from '../assets/React-icon.svg.png'
import materialUI from '../assets/logo.png'
import tailwind from '../assets/tailwind.png'
import figma from '../assets/figma.png'
import confluence from '../assets/confluence.png'
import jira from '../assets/jira.png'
import trello from '../assets/trello.png'
import git from '../assets/git.png'
import maze from '../assets/maze.avif'
import eslint from '../assets/eslint.png'
import scss from '../assets/scss.png'
import sanity from '../assets/sanity.png'
import nextjs from '../assets/nextjs.png'
import gatsby from '../assets/gatsby.png'
import graphql from '../assets/graphql.png'

import SkillTag from './SkillTag'
import SkillTab from './SkillTab'

import languageIcon from '../assets/languages.png'
import frameworkIcon from '../assets/framework.png'
import toolsIcon from '../assets/tools.png'

const TechStack = ({ techStackScrollRef }) => {
  const isSelected = false;
  const selectedStyling = isSelected ? 'hover:bg-secondaryBlue bg-opacity-20' : '';

  return (
    <div className='section pt-0'>
        <div className='default-margin-layout'>
            <section 
                className='tech-stack-section show-on-scroll'
                id='TechStack'
                ref={techStackScrollRef}>
                  <h1 className='tech-stack-header'>/ Tech Stack</h1>
                  <p className='tech-stack-subheader'>Here's some of the technologies I've worked with: </p>
                  <div className='skills-container'>
                    <div
                      className='skill-tabs'
                      aria-label='tabs'
                    >
                      <div className='slider indicator'></div>
                      <button
                        role='tab'
                        aria-selected='true'
                        aria-controls='panel-1'
                        id='tab-1'
                        tabIndex={0}
                        className='tab w-[100%]'>
                        <SkillTab 
                          // imgSrc={languageIcon}
                          // darkImgSrc={languageIcon}
                          tabName='Languages'>
                        </SkillTab>
                        {/* <span className='align-middle'>Languages</span> */}
                      </button>
                      <button
                        role='tab'
                        aria-selected='false'
                        aria-controls='panel-2'
                        id='tab-2'
                        tabIndex={-1}
                        className='tab w-[100%]'>
                        <SkillTab
                          // imgSrc={frameworkIcon}
                          // darkImgSrc={frameworkIcon}
                          tabName='Frameworks'
                        ></SkillTab>
                        {/* <span className='align-middle'>Frameworks</span> */}
                      </button>
                      <button
                        role='tab'
                        aria-selected='false'
                        aria-controls='panel-3'
                        id='tab-3'
                        tabIndex={-1}
                        className='tab w-[100%]'>
                      <SkillTab
                        // imgSrc={toolsIcon}
                        // darkImgSrc={toolsIcon}
                        tabName='Tools'
                      ></SkillTab>
                        {/* <span className='align-middle'>Tools</span> */}
                      </button>
                    </div>
                    <div className="skill-panels">
                      <div className="tab-panel flex gap-3 flex-wrap h-max" id='panel-1'>
                        <SkillTag skillName='Python' logo={python}></SkillTag>
                        <SkillTag skillName='HTML' logo={html}></SkillTag>
                        <SkillTag skillName='CSS' logo={css}></SkillTag>
                        <SkillTag skillName='PostgreSQL' logo={postgresql}></SkillTag>
                        <SkillTag skillName='JavaScript' logo={javascript}></SkillTag>
                      </div>
                      <div className="tab-panel hidden opacity-0 gap-3 flex-wrap h-max" id='panel-2'>
                        <SkillTag skillName='React' logo={react}></SkillTag>
                        <SkillTag skillName='NextJS' logo={nextjs}></SkillTag>
                        <SkillTag skillName='Gatbsy' logo={gatsby}></SkillTag>
                        <SkillTag skillName='SASS' logo={scss}></SkillTag>
                        {/* <SkillTag skillName='React Native' logo={react}></SkillTag> */}
                        <SkillTag skillName='Material UI' logo={materialUI}></SkillTag>
                        <SkillTag skillName='TailwindCSS' logo={tailwind}></SkillTag>
                        <SkillTag skillName='GraphQL' logo={graphql}></SkillTag>
                      </div>
                      <div className="tab-panel hidden opacity-0 gap-3 flex-wrap h-max" id='panel-3'>
                        <SkillTag skillName='Figma' logo={figma}></SkillTag>
                        <SkillTag skillName='Maze' logo={maze}></SkillTag>
                        <SkillTag skillName='Jira' logo={jira}></SkillTag>
                        <SkillTag skillName='Confluence' logo={confluence}></SkillTag>
                        <SkillTag skillName='Trello' logo={trello}></SkillTag>
                        <SkillTag skillName='Git' logo={git}></SkillTag>
                        <SkillTag skillName='Eslint' logo={eslint}></SkillTag>
                        <SkillTag skillName='Sanity' logo={sanity}></SkillTag>
                      </div>
                    </div>
                  </div>
              </section>
        </div>
    </div>
  )
}

export default TechStack